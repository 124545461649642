
import React, { useState, useEffect } from 'react';

//import { Helmet } from 'react-helmet';
import {
	Link, NavLink, useNavigate, useRevalidator
} from "react-router-dom";

import { Transition, Menu } from '@headlessui/react'

import {
  RendererE, ButtonE, useAuth, getE //, postE
} from "./e-no-deps";

import { Table, Spinner, AutoComplete, Breadcrumbs, Modal, Radio } from "@geist-ui/react";
//import { Themes } from "@geist-ui/core";

import { Sun, Moon, Globe } from '@geist-ui/react-icons'

import okImg from './ok.png';
import koImg from './ko.png';

import ita from './ita.gif';
import eng from './eng.jpeg';

export function TableGE(props) {
  const cols = props.cols.map((o, idx) => {
		if (o.render)
			return <Table.Column key={idx} prop={o.prop} label={o.label} render={o.render} />;
		else
			return <Table.Column key={idx} prop={o.prop} label={o.label} />;
  });
  return <Table data={props.data}>{cols}</Table>;
}

export function SpinnerE(props) {
  return <Spinner scale={4} />;
}

export function NavigationItem(props) {
	const iconsize = props.iconsize || 36;
	const Icon = RendererE(props.icon);
	return (
		<NavLink to={props.link} className={props.className} >
			<Icon size={iconsize} />
			<span>{props.text}</span>
		</NavLink>
	);
}

function reloadDark() {
	if (localStorage.theme === 'dark' || 
		(!('theme' in localStorage) && 
		window.matchMedia('(prefers-color-scheme: dark)').matches)) {
		document.documentElement.classList.add('dark')
		return true;
	} else {
		document.documentElement.classList.remove('dark')
		return false;
	}
}

export function ToggleDark(props) {
	const [dark, setDark] = useState(reloadDark);
	// :root in css
	function handler(e) {
		e.preventDefault();
		let def_dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
		if (dark) {
			if (!def_dark)
				localStorage.removeItem('theme');
			else
				localStorage.theme = 'light';
		}
		else {
			if (def_dark)
				localStorage.removeItem('theme');
			else
				localStorage.theme = 'dark';
		}
		setDark(reloadDark());
	}
	
	return (
		<ButtonE className="p-3" to="" onClick={handler}>
			{ 
				dark
				?
				<Sun/>
				:
				<Moon />
			}
		</ButtonE>
	);
}

const languages = [
	{ id: "en", name: "English", icon: eng },
	{ id: "it", name: "Italiano", icon: ita },
];

//<link href="/path/to/german/version" rel="alternate" hreflang="de">
export function ToggleLang(props) {
	let revalidator = useRevalidator();

	//const def = document.documentElement.lang;
	const def = props.def || "en";
	const [langElm, setLangElm] = useState(languages.find(o => o.id === def));

	function onClick(e) {
		e.preventDefault();
		let t = e.target;
		console.dir(t);
		let i = 0;
			console.log("ok");
		while (i<6) {
			//if (t.dataset && t.dataset.id)
			if (t.hreflang)
				break;
			t = t.parentNode;
			i++;
		}
		//let id = t.dataset.id;
		let id = t.hreflang;
		if (!id) {
			console.log("!id");
			return;
		}
		if (props.i18n) {
			props.i18n.changeLanguage(id);
			revalidator.revalidate();
		}
		setLangElm(languages.find(o => o.id === id));
	}
	const langs = languages.map((o, idx) => {
		return (
			<Menu.Item key={idx}>
				{({ active }) => (
					<Link to="#" onClick={onClick} hrefLang={o.id} lang={o.id} >
						<div className={`flex flex-row gap-x-2 items-center justify-center p-2
								${active ? "bg-indigo-500 text-white" : ""}
							`}>
							<img src={o.icon} className="w-[30px] h-[20px]" alt={o.name} />
							<div>{o.name}</div>
						</div>
					</Link>
				)}
			</Menu.Item>
		);
	});

	return (
		<div className="relative">
			<Menu>
				<Menu.Button>
					<img src={langElm.icon} className="w-[30px] h-[20px]" alt={langElm.name} />
				</Menu.Button>

				<Transition
					enter="transition duration-100 ease-out"
					enterFrom="transform scale-95 opacity-0"
					enterTo="transform scale-100 opacity-100"
					leave="transition duration-75 ease-out"
					leaveFrom="transform scale-100 opacity-100"
					leaveTo="transform scale-95 opacity-0"
				>
					<Menu.Items className="flex flex-col items-center justify-center absolute z-10 bg-w dark:bg-b border border-black dark:border-white p-4 -left-36 w-[200px]" >
						<div lang="en">Change Language</div>
						{langs}
					</Menu.Items>
				</Transition>

			</Menu>
		</div>
  );
}

export const nop = () => { return true };

export function InputCE(props) {
	const def = {
		height: "h-[40px]",
		offset1: "-top-[2px]",
		offset2: "-top-[24px]"
	};
	const required = props.required || false;
	const label = props.label || "label";
	const type = props.type || "text";
	const name = props.name || label;
	const value = props.value || "";
	const onChange = props.onChange || nop;
	const height = props.height || def.height;
	const offset1 = props.offset1 || def.offset1;
	const offset2 = props.offset2 || def.offset2;
	//(e) => {props.onChange(e.target.value)}
	function onCh(e) {
		onChange(e.target.value);
		/*
		e.target.setCustomValidity("");
		if (props.notInt)
			props.onChange(e.target.value);
		else
			props.onChange(parseInt(e.target.value));
			*/
	}
		//<input className={props.className} type={type} name={name} value={value} onChange={onCh}
		//required={required} placeholder={props.placeholder} />
	/*
			<label
				for="email"
				className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
			>
			duration-200 ease-in-out [transition-property:top,font-size]
	*/
	return (
		<label className="relative">
			<input
				name={name}
				type={type}
				required={required}
				placeholder={label}
				className={`peer placeholder-transparent ${height} ${props.className}`}
				value={value}
				onChange={onCh}
			/>
			<div
				className={`transition-all absolute left-0
				${offset2} text-sm text-gray-600
				peer-placeholder-shown:${offset1} peer-placeholder-shown:text-base
				peer-focus:${offset2} peer-focus:text-sm
				`}
			>
				{label}
			</div>
		</label>
	);
}

export function DropdownMenu() {
  //const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="min-h-screen bg-indigo-100 flex justify-end p-4">
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Options Menu
              <Globe className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              show={open}
              enter="transform transition duration-100 ease-in"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transform transition duration-75 ease-out"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-w ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                static
              >
                <div ClassName="py-1">
                  <Menu.Item>
                    {({ active }) => (
// eslint-disable-next-line
                      <a
                        href="#"
                        className={`flex items-center px-4 py-2 text-sm
                    ${active ? "bg-indigo-500 text-white" : "text-gray-700"}
                   `}
                      >
                        <Globe
                          className={`mr-3 h-5 w-5
                     ${active ? "text-white" : "text-gray-400"}
                    `}
                          aria-hidden="true"
                        />
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div ClassName="py-1">
                  <Menu.Item>
                    {({ active }) => (
// eslint-disable-next-line
                      <a
                        href="#"
                        className={`flex items-center px-4 py-2 text-sm
                    ${active ? "bg-indigo-500 text-white" : "text-gray-700"}
                   `}
                      >
                        <Globe
                          className={`mr-3 h-5 w-5
                     ${active ? "text-white" : "text-gray-400"}
                    `}
                          aria-hidden="true"
                        />
                        Duplicate
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div ClassName="py-1">
                  <Menu.Item>
                    {({ active }) => (
// eslint-disable-next-line
                      <a
                        href="#"
                        className={`flex items-center px-4 py-2 text-sm
                    ${active ? "bg-indigo-500 text-white" : "text-gray-700"}
                   `}
                      >
                        <Globe
                          className={`mr-3 h-5 w-5
                     ${active ? "text-white" : "text-gray-400"}
                    `}
                          aria-hidden="true"
                        />
                        Archive
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export function SelectorGE1(props) {
	//const [options, setOptions] = useState([ {value: -1, label: "[select]"} ]);
	const [options, setOptions] = useState([ ]);
	const [viewOptions, setViewOptions] = useState([ ]);
	const [viewValue, setViewValue] = useState("");
	const onChange = props.onChange || nop;
	const label = props.label || "";
	//const initialValue = props.initialValue || 0;
	/*
  const allOptions = [
    { label: 'London', value: 'london' },
    { label: 'Sydney', value: 'sydney' },
    { label: 'Shanghai', value: 'shanghai' },
  ]
	*/
  let auth = useAuth();

  const searchHandler = (currentValue) => {
		setViewValue(currentValue);
    const createOptions = [{
      value: currentValue, label: 'Add "' + currentValue + '"'
    }]
    if (!currentValue) {
			return setViewOptions([])
		}
    const relatedOptions = options.filter(item => item.label.includes(currentValue))
    const optionsWithCreatable = relatedOptions.length !== 0 ? relatedOptions : createOptions
    setViewOptions(optionsWithCreatable)
  }

  const selectHandler = (currentValue) => {
		console.log(currentValue);
		let i = options.findIndex((f) => f.value === currentValue);
		if (i ===-1) {
			console.log("new");
		}
		else {
			onChange(options[i].v);
		}
  }
	
	//TODO togliere
	useEffect(() => {
		let isMounted = true;
		let ep = auth.user.endpoint;
		ep += `/api/${props.entity}`;
		if (props.params) {
			ep += `${props.params}`;
		}
		getE(ep, { token: auth.user.token, entity: props.entity }).then((e) => {
			if (!e || !e.data) {
				console.log(e);
				return;
			}
			let opts = e.data.map((o, idx) => {
				return {v: o.id, value: o.attributes.name, label: o.attributes.name };
			});
			//console.log(opts);
			if (isMounted)
				setOptions(opts);
			//setPageNum(e.data.attributes.num);
			//console.log(inp);
		});
		return () => { isMounted = false };
	}, []);

			//<SelectorE2 options={options} onChange={onChange} className={className} />
				//dropdownStyle={{"color":"red"}} dropdownClassName="guilabel" />
	return (
		<label className="flex flex-row gap-x-3">
			{label}
			<AutoComplete style={{"color":"white"}} options={viewOptions} value={viewValue} disableFreeSolo
			onSearch={searchHandler} onSelect={selectHandler} />
		</label>
	);
}

export function RadioE(props) {
	const onChange = props.onChange || nop;
	const value = props.value || "";
  const opts = props.options.map((o, idx) => {
    return  (
			<Radio value={o["v"]} key={idx}>
				{o["k"]}
				<Radio.Desc>{o["d"]}</Radio.Desc>
			</Radio>
		);
  });

	return (
		<Radio.Group value={value} onChange={onChange} useRow >
			{opts}
		</Radio.Group>
	);
}

export function BreadcrumbsE(props) {
	/*
  const cols = props.cols.map((o, idx) => {
    return <Table.Column key={idx} prop={o.prop} label={o.label} />;
  });
  //return <Table data={props.data}>{cols}</Table>;
	*/
	return (
		<Breadcrumbs>
			<Breadcrumbs.Item>Home</Breadcrumbs.Item>
			<Breadcrumbs.Item href="">Catalog</Breadcrumbs.Item>
			<Breadcrumbs.Item>Page</Breadcrumbs.Item>
		</Breadcrumbs>
	);
}

export function closeModal(modal) {
	return (e) => {
		if (modal.current)
			modal.current.setState(false, "wait", "", "");
	}
}

export function confirmModal(modal, setArg) {
	return (e) => {
		let arg = e.target.dataset.arg;
		if (setArg)
			setArg(arg);
		if (modal.current)
			modal.current.setState(true, "wait", "operation confirm", "are you sure?");
	}
}

export const ModalE = React.forwardRef((props, ref) => {
	const navigate = useNavigate();
	const [modalVisible, setModalVisible] = useState(false);
	const [modalState, setModalState] = useState({state: "wait", subtitle: "", content: ""});
	const title = props.title || "App";
	const back = props.back || null;
	const states = {
		"wait": <Spinner scale={19} className="h200" />,
		"ok": <img src={okImg} className="mt-1 h200" alt="ok" />,
		"ko": <img src={koImg} className="mt-1 h200" alt="ko" />,
		"null": <></>
	};
	const modalCloseHandler = props.closeHandler || defaultCloseHandler;
	const buttons = props.buttons || [["close", modalCloseHandler]];
	const buttonsC = buttons.map((o, idx) => {
    return <Modal.Action key={idx} passive onClick={o[1]}>{o[0]}</Modal.Action>;
	});

	function defaultCloseHandler(e) {
		if (back) {
			if (modalState.state !== "ok")
				setModalVisible(false);
			else {
				navigate(back);
			}
		}
		else {
			setModalVisible(false);
		}
	}

	React.useImperativeHandle(ref, () => {
		return {
			setState: (visible, state, subtitle, content) => {
				if (!visible) {
					setModalVisible(visible);
					return;
				}
				setModalState({state, subtitle, content});
				setModalVisible(visible);
			}
		}
	}, [setModalVisible, setModalState]);

	return (
		<Modal visible={modalVisible} onClose={modalCloseHandler}>
			<Modal.Title>{title}</Modal.Title>
			<Modal.Subtitle>{modalState.subtitle}</Modal.Subtitle>
			<Modal.Content className="flex flex-col gap-y-4 items-center justify-center h200" >
				<code>{modalState.content}</code>
			{states[modalState.state]}
			</Modal.Content>
			{buttonsC}
		</Modal>
	);
});

