import React, { useState } from "react";

import {
  Link,
	Outlet,
  useParams,
  useLocation,
  useNavigate,
	redirect,
	//useActionData,
	useLoaderData,
  RouterProvider,
	createBrowserRouter,
} from "react-router-dom";

import { GeistProvider, useToasts } from "@geist-ui/react";
import { useTranslation, Trans } from "react-i18next";
import Markdown from 'marked-react';

import {
	StrapiLoader,
  NotFoundPageE,
  ErrorPageE,
  getE,
  postE,
  InputUE,
  ButtonE,
  AuthProvider,
  RequireAuth,
  AppLocker,
  useAuth,
	NavigationItem,
	Icon,
	//alwaysFalse,
	//alwaysTrue,
} from "./common/e-no-deps";

import { SelectorGE1, BreadcrumbsE, ModalE, ToggleDark, ToggleLang } from "./common/e-geist";
import i18n from "./i18n";

import "./Peewit.css";

import logo from "./common/logo.svg";

const endpoint = "https://app.peew.it:3008";
const appName = "peew.it";

function ProvaPage() {
  return (
    <div className="flex flex-col">
    <div className="flex flex-row gap-10 bg-gray-500 justify-around p-4">
      <div className="border border-gray-700 rounded bg-gray-600 p-2">
        All Links
      </div>
      <InputUE
        placeholder="search"
        className="border border-gray-800 rounded-full p-2"
      />
      <ButtonE className="border border-orange-700 bg-orange-600 p-2">
        create
      </ButtonE>
    </div>
    <div className="flex flex-row p-5 items-start justify-between">

      <div className="flex flex-col p-5 items-start">
      <div className="font-extrabold text-2xl">
        Links
      </div>
      <div className="flex flex-row p-4 gap-x-10">
        <div className="">
          Date created
        </div>
        <div>
          Top performing
        </div>
      </div>
      <ButtonE className="border border-blue-800 bg-blue-600 p-2 font-bold rounded-lg">
        Filters
      </ButtonE>
    </div>

    <div className="flex flex-row p-4 gap-x-3">
      <ButtonE className="border border-blue-800 bg-blue-600 p-2 font-bold rounded-lg">
        Upgrade for custom links
      </ButtonE>
      <ButtonE className="border border-blue-400 bg-blue-300 p-2 font-bold rounded-lg">
        SELECT DATE
      </ButtonE>
    </div>
  </div>

    <div className="flex flex-row p-5 h-64">

      <div className="flex flex-col bg-blue-100 h-full">
        <div className="flex flex-row p-4 gap-x-40">
    <div>
      0 Results
    </div>
    <div>
      Clicks all time
    </div>
  </div>
  <ButtonE className="border border-orange-800 bg-orange-600 p-2 font-bold rounded-lg">
    Create your first link
  </ButtonE>
  </div>

  <div className="content-center">
    After your links get clicks, we'll display your link information, options, and performance data.
  </div>

    </div>
  </div>
  );
}

function Navigation(props) {
	const { t } = useTranslation();
  let auth = useAuth();
  let user = auth.user;

	let userLi = <li><NavigationItem icon="login" text={t("login")} link={t("login")} /></li>;
	let logout = <></>;
	if (user) {
		userLi =  <li><NavigationItem icon="person" text={"menu"} link={"/user/menu"} /></li>;
		logout = <ButtonE onClick={auth.signout} className="p-0"><Icon name="logout" /></ButtonE>;
	}

  return (
    <>
      <nav>
        <ul>
					<li>
						<NavigationItem text="peew.it" icon="home" link="home" />
					</li>
					<li>
						<NavigationItem icon="sell" text={t("pricing")} link={t("pricing")} />
					</li>
					<li>
						<NavigationItem icon="quiz" text={t("faqs")} link={t("faqs")} />
					</li>
					{userLi}
					<li>
						<div className="flex flex-row gap-x-2 items-center">
							{logout}
							<ToggleDark />
							<ToggleLang def="it" i18n={i18n} />
						</div>
					</li>
        </ul>
      </nav>
      <Outlet />
    </>
  );
}

function PageLoader(obj) {
	return async () => {
		let ep = endpoint;
		let entity = "pages";
		let ids = 1;
		ids = obj[i18n.language];
		ep += `/api/${entity}/${ids}?populate=body`;
		return getE(ep).then((e) => {
			if (!e || !e.data) {
				console.log(e);
				return null;
			}

			return e.data.attributes.body;
		});
	}
}

function HomePage(props) {
	const data = useLoaderData();

  return (
    <main>
        <div className="flex flex-col pt-65">
					{
						data?.map((o, idx) => {
								return (
                <div
                  key={idx} className="flex flex-row pb-12 text-left" >
                  <Icon className="text-[130px]" name={o.icon} />
                  <div className="flex flex-col pl-12">
									<div className="font-bold text-5xl p-3" >{o.title}</div>
									<div><Markdown>{o.text}</Markdown></div>
                </div>

              </div>
            )
							})
					}
        </div>
    </main>
  );
}

function FaqsPage(props) {
	const data = useLoaderData();

  return (
    <main>
        <div className="flex flex-col pt-65">
					{
						data?.map((o, idx) => {
              return (
              <div
                key={idx} className="flex flex-row pb-12 text-left" >
                <Icon className="text-[130px]" name={o.icon} />
                <div className="flex flex-col pl-12">
                <div className="font-bold text-5xl p-3" >{o.title}</div>
                <div><Markdown>{o.text}</Markdown></div>
              </div>

            </div>
          )
							})
					}
        </div>
    </main>
  );
}

function Login(props) {
	const { t } = useTranslation();
  let navigate = useNavigate();

  let auth = useAuth();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

	function onSubmit(e) {
    e.preventDefault();
		let form = e.currentTarget;
    let formData = new FormData(form);
		auth.signin({formData: formData}, onPostSubmit);
		//postE(ep, {formData: formData}).then(onPostSubmit);
	}

	function onPostSubmit(e) {
		//navigate(from, { replace: true });
		navigate("/user/menu");
	}

  return (
    <div className="loginbox">
      <div className="md:w-1/2 w-full">
        <h3 className="mt-10 log1">{t("welcome-to")}</h3>
        <h2 className="mb-10 log1">{appName}</h2>

        <form onSubmit={onSubmit} className="signupbox" id="signup">
          <input
            className="inEP"
            type="email"
            name="identifier"
            required
            placeholder="Email"
          />
          <input
            className="inEP"
            type="password"
            name="password"
            required
            placeholder="Password"
          />
          <input type="submit" className="log-sub" value={t("login")} />

					<div className="">{t("login-continue")}</div>
					<Link to={"/"+t("signup")}>{t("login-to-signup")}</Link>
        </form>
      </div>
      <div className="md:w-1/2 md:block hidden">
        <img
          src={logo}
          className="logotitle pt-24 pr-12"
          alt="logo"
        />
      </div>
    </div>
  );
}

function Signup(props) {
	const { t } = useTranslation();
	const modal = React.createRef();

	function onSubmit(e) {
    e.preventDefault();
		modal.current.setState(true, "wait", "registration", "");

		let form = e.currentTarget;
    let formData = new FormData(form);
		formData.append("username", formData.get("email"));
		let ep = endpoint;
		ep += "/api/auth/local/register";
		postE(ep, {formData: formData}).then(onPostSubmit);
	}

	function onPostSubmit(e) {
		if (!e || !e.jwt) {
			let err = "";
			if (e.error && e.error.message)
				err = e.error.message;

			modal.current.setState(true, "ko", "registration failed", err);
			return;
		}
		modal.current.setState(true, "ok", "registration success", "");
	}

  return (
    <div className="loginbox">
      <div className="md:w-1/2 w-full">
        <h3 className="mt-10 log1">{t("welcome-to")}</h3>
        <h2 className="mb-10 log1">{appName}</h2>

        <form onSubmit={onSubmit} className="signupbox" id="signup">
					<ModalE back={"/"+t("login")} title="peew.it" ref={modal} />
          <input
            className="inEP"
            type="email"
            name="email"
            required
            placeholder="Email"
          />
          <input
            className="inEP"
            type="password"
            name="password"
            required
            placeholder="Password"
          />
          <input type="submit" className="log-sub" value={t("signup")} />

					<div className="">{t("login-continue")}</div>
					<Link to={"/"+t("login")}>{t("signup-to-login")}</Link>
        </form>
      </div>
      <div className="md:w-1/2 md:block hidden">
        <img
          src={logo}
          className="logotitle pt-24 pr-12"
          alt="logo"
        />
      </div>
    </div>
  );
}

function NewLinkPage(props) {
	const auth = useAuth();
	const { t } = useTranslation();
	const modal = React.createRef();

	function onSubmit(e) {
		e.preventDefault();

		modal.current.setState(true, "wait", "new link", "");

		let form = e.currentTarget;
		let fd = new FormData(form);

		let data = {};
		data["url"] = fd.get("url");
		data["name"] = fd.get("name");

		let formData = new FormData();
		formData.append('data', JSON.stringify(data));

		let ep = auth.user.endpoint;
		ep += "/api/short-links";
		postE(ep, {token: auth.user.token, formData: formData}).then(onPostSubmit);
	}

	function onPostSubmit(e) {
		if (!e || !e.data) {
			let err = "";
			if (e.error && e.error.message) {
				//let err = <div>e.error.message</div>;
				err = e.error.message;
				if (e.error.details && e.error.details.errors) {
					err = "";
					e.error.details.errors.forEach((o) => {
						//err += <div>o.message</div>;
						err += o.message;
						err += "\n";
					});
				}
			}
			modal.current.setState(true, "ko", "creation failed", err);
			return;
		}
		modal.current.setState(true, "ok", "creation success", "");
	}

	return (
		<div className="p-24 flex flex-col">
			<form onSubmit={onSubmit} className="" id="newlink">
				<ModalE back={"/user/"+t("menu")} title="peew.it" ref={modal} />
				<fieldset className="border border-2 border-orange-600 border-4 rounded-2xl">
					<legend className="text-4xl text-orange-500">New Link</legend>
					<div className="flex flex-wrap gap-y-8 gap-x-16 p-20 justify-center">
						<InputUE className="border-white rounded-lg bg-white w-1/3 p-4"
							placeholder="Insert long URL" name="url" type="url" />
						<ButtonE className="border-white rounded-lg w-1/3 p-2 m-2">
							UTM / other tags
						</ButtonE>
						<InputUE className="border-white rounded-lg bg-white w-1/3 p-4"
							placeholder="Slash tag" name="name" />
						<ButtonE className="border-white rounded-lg w-1/3 p-2 m-2">
							Slash tag style
						</ButtonE>
						<ButtonE type="submit" className="border-white rounded-lg bg-orange-500 font-bold text-2xl text-black p-4 mt-10 w-1/3">
							Create link
						</ButtonE>
					</div>
				</fieldset>
			</form>
		</div>
	);
}

function LinkPage(props) {
	const data = useLoaderData();

	return (
		<div>
			<div className="flex flex-col">
				<div className="grid grid-cols-2 grid-rows-3 p-20 gap-y-10">
					<div className="font-bold text-4xl self-end">
						Short link
					</div>
					<div className="font-bold text-4xl self-end">
						Destination
					</div>

					<div className="font-bold text-2xl p-2">
						<div className="pb-10">
							{data?.attributes.name}
						</div>
						<hr className="w-10/12 border-orange-500 border-2"/>
					</div>

					<div className="font-bold text-2xl p-2">
						<div className="pb-10">
							{data?.attributes.url}
						</div>
						<hr className="w-10/12 border-orange-500 border-2"/>
					</div>
					<div className="self-end">
						<ButtonE className="font-bold border-white rounded-lg bg-orange-500 font-bold text-2xl text-black p-3 w-1/3 col-span-2">
							Generate QR
						</ButtonE>
					</div>
					<div className="font-bold text-xl p-2 self-end">
						data.... by tizio
					</div>
				</div>

			</div>

			<div className="bg-white p-20">
				grafici
			</div>

		</div>

	);
}

function MenuPage(props) {
	const { t } = useTranslation();
	const data = useLoaderData();

	return (
		<main>
			<div className="flex flex-col pt-65">
				<Link to={"../newlink"}
					className="font-bold border-white rounded-lg bg-orange-500 font-bold text-2xl text-black p-3 w-1/3 col-span-2 self-center">
					{t("create-new-link")}
				</Link>
				<hr className="w-10/12 border-orange-600 border-2 self-center mb-20 mt-20"/>
				{ data.length ?
					<>
						{
							data.map((o, idx) => {
								//return <Link key={idx} to={o.attributes.nearest_id} imp={o.attributes.importanza} />;
								return <Link key={idx} to={"../link/"+o.id}>{appName+"/"+o.attributes.name}</Link>;
							})
						}
					</>
					:
					<div className="text-black font-bold text-4xl text-orange-600 self-center">
						{t("links-empty")}
					</div>
				}
			</div>
		</main>
	);
}

function AppRouter() {
  let auth = useAuth();

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Navigation />,
			//errorElement: <NotFoundPageE />,
			children: [
				{
					index: true,
					loader: () => { return redirect("/home"); },
				},
				{
					path: "home",
					element: <HomePage />,
					loader: PageLoader({it: 1, en: 5}),
					//shouldRevalidate: alwaysFalse,
					errorElement: <ErrorPageE />
				},
				{
					path: "login",
					element: <Login />,
				},
				{
					path: "accedi",
					element: <Login />,
				},
				{
					path: "signup",
					element: <Signup />,
				},
				{
					path: "registrati",
					element: <Signup />,
				},
				{
					path: "domande",
					element: <FaqsPage />,
					loader: PageLoader({it: 7, en: 6}),
					errorElement: <ErrorPageE />
				},
				{
					path: "faqs",
					element: <FaqsPage />,
					loader: PageLoader({it: 7, en: 6}),
					errorElement: <ErrorPageE />
				},
				{
					path: "user/",
					children: [
						{
							path: "menu",
							element: <RequireAuth><MenuPage /></RequireAuth>,
							loader: StrapiLoader(null, "short-links", { auth } ),
							//shouldRevalidate: alwaysFalse,
							errorElement: <ErrorPageE />
						},
						{
							path: "newlink",
							element: <RequireAuth><NewLinkPage /></RequireAuth>,
							errorElement: <ErrorPageE />
						},
						{
							path: "link/:ids",
							element: <RequireAuth><LinkPage /></RequireAuth>,
							loader: StrapiLoader(null, "short-links", { auth } ),
							errorElement: <ErrorPageE />
						},
					]
				},
				{
					path: "*",
					element: <NotFoundPageE />,
				},
			]
		},
	]);

	return <RouterProvider router={router} />;
}

function App() {
  return (
		<AppLocker>
		<GeistProvider themeType="dark">
			<AuthProvider endpoint={endpoint}>
				<AppRouter />
			</AuthProvider>
		</GeistProvider>
		</AppLocker>
  );
}

export default App;
