import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
			pricing: "pricing",
			faqs: "faqs",
			login: "login",
			signup: "signup",
			"welcome-to": "Welcome to",
			"login-continue": "or continue with Facebook or Google account",
			"login-to-signup": "no account yet? sign up here",
			"signup-to-login": "have ypu got an account? log in here",
			"create-new-link": "create new short link",
			"links-empty": "You don't have any short link",
			"business": `
			<0>
				Do you have a business?
			</0>
			<1>
				Become part of the community!
			</1>
			<2>
				Sign up as a business account
			</2>`,
    }
  },
  it: {
    translation: {
			pricing: "prezzi",
			faqs: "domande",
			login: "accedi",
			signup: "registrati",
			"welcome-to": "Benvenuti in",
			"login-continue": "o continua con il tuo account Facebook o Google",
			"login-to-signup": "non sei ancora registrato? registrati qui",
			"signup-to-login": "sei registrato? accedi qui",
			"create-new-link": "crea nuovo short link",
			"links-empty": "Non hai nessuno short link",
			"business": `
			<0>
				Hai un'attività commerciale?
			</0>
			<1>
				Entra a far parte della community!
			</1>
			<2>
				Iscriviti come account business
			</2>`,
    }
  }
};
i18n.use(initReactI18next).init({
  resources,
  debug: false,
  lng: "it",
  fallbackLng: "it",
  whitelist: ["it", "en"],
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});
export default i18n;

