
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import App from "./Peewit";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

serviceWorkerRegistration.register();

reportWebVitals();
