
import React, { useState, useEffect, useRef  } from 'react';

import {
	Link,
	NavLink,
	Navigate,
	useNavigate,
  useLocation,
	useRouteError,
} from "react-router-dom";

export const empty = [];
export const nop = () => { return true };
export const NoYes = [{k:"no", v:0},{k:"yes", v:1}];
const set_data = "set-data";

//var cookie = "";
//var token = false;
var reqLocal = false;
var api_entry_point = false;

export function DateToE(d) {
	/*return d.toString().replace(new RegExp("(?<=:\\d\\d)\\s"), "<br />");*/
	return d.toString();
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function RendererE(Wrapped) {
	return (props) => {
		return (<Wrapped {...props} />);
	}
}

export function NotFoundPageE(props) {
	//const iconsize = props.iconsize || 36;
	return (
		<main>
			Not Found
		</main>
	);
}
export function ErrorPageE(props) {
	let error = useRouteError();
  console.error(error);

	return (
		<main>
			Error
		</main>
	);
}

export function Icon(props) {
	//const iconsize = props.size || 36;
	const className = props.className;
	const icon = props.name || "";
	return <span className={"material-symbols-outlined "+className}>{icon}</span>;
}

export function NavigationItem(props) {
	return (
		<NavLink to={props.link} className={props.className} >
			<Icon name={props.icon} size={props.iconsize} />
			<span>{props.text}</span>
		</NavLink>
	);
}

export function LinkNames(props) {
	const names = props.names || [];
	return (
		<main className="flex flex-col gap-y-6">{
			names.map((name, idx) => {
				return <Link key={idx} to={name}>{name}</Link>
			})
		}</main>
	);
}

export function LinkName(props) {
	const name = props.name || "";
	return (
		<Link to={name}>{name}</Link>
	);
}

export function InfoButton(props) {
	const def = props.txt.match(/[a-zA-Z0-9]*/);
	const cl = "but1";

	function click() {
		window.location.href = props.txt;
	}
	if (props.a) 
		return (<a id="txt" href={props.txt}>{def}</a>);
	else if (props.textarea)
		return <textarea id="txt" onClick={() => {
			if (navigator.clipbloard) {
				navigator.clipboard.writeText(props.txt);
			}
			else {
				let txt = document.getElementById("txt");
				txt.focus();
				txt.select();
				document.execCommand("Copy");
			}
		}}>{props.txt}</textarea>
	else
		return (<button className={cl} onClick={click} >{def}</button>);
}

const newRegExp = new RegExp(":\\d\\d\\s");
export function printDateE(d) {
	let str = d.toString();
	let i = str.match(newRegExp);
	return str.slice(0, i.index);
}

export function setFDKey(state, setState) {
	return (key) => {
		return (v) => {
			//state[key] = v;
			//setState(state);
			state[key] = v;
			setState({...state});
		}
	}
}

export function arrayClone(arr, depth1, all) {
	if (all)
		return JSON.parse(JSON.stringify(arr));
	if (depth1)
		return arr.map(a => ({...a}));
	else
		return arr.slice(); //[].concat(arr) [...originalArray]
}

export function preE(o) {
	return (
		<pre>
		</pre>
	);
}

export function Page0E(props) {
	return (
		<div className="cen mw">
			{
				props.tit && <h2>{props.tit}</h2>
			}
			{props.children}
		</div>
	);
}

export function Page1E(props) {
	return (
		<div className="cen">
			{
				props.tit && <h2>{props.tit}</h2>
			}
			{props.children}
		</div>
	);
}

export function TableE(props) {
	const ph = props.headers;
	const headers = ph.map((h, idx) => {
		return <th key={idx}>{h[0]}</th>
	});
	const rows = props.data.map((c, idx) => {
		const row = ph.map((h, jdx) => {
			return <td key={jdx}>{h[1](c)}</td>
		});
		return <tr key={idx}>{row}</tr>
	});
	var tab;
	if (props.emptyMsg && !props.data.length) {
		tab = <div>{props.emptyMsg}</div>;
	}
	else {
		tab = <table>
		<thead><tr>{headers}</tr></thead>
		<tbody>
		{rows}
		</tbody>
		</table>;
	}
	return (
		<div className="cb">
		{
			props.ico && <img src={props.ico} alt="icon" className="ico1" />
		}
		{
			props.tit && ( props.ico ?
				<h2 className="lh1 fs1 icotit1">{props.tit}</h2> : 
				<h2 className="lh15 fs1">{props.tit}</h2> )
		}
		{
			props.desc && <div>{props.desc}</div>
		}
		{tab}
		</div>
	);
}

export function getDate(da, ti, tz) {
	let str = da+" "+ti;
	let dt;
	if (tz === 1)
		str = da+"T"+ti+"Z";
	dt = new Date(str);
	return dt;
}

export function getODate(d) {
	let str = "";
	if (!d) {
		return str;
	}
	str += d.getFullYear()+"-";
	if (d.getMonth()+1<10)
		str += "0";
	str += (d.getMonth()+1)+"-";
	if (d.getDate()<10)
		str += "0";
	str += d.getDate();
	return str;
}
export function getOTime(d) {
	let str = "";
	if (!d) {
		return str;
	}
	if (d.getHours()<10)
		str += "0";
	str += d.getHours()+":";
	if (d.getMinutes()<10)
		str += "0";
	str += d.getMinutes();
	return str;
}
export function TimeToStr(d) {
	return d.toString();
}

export function DateTimePickerE(props) {
	const [date, setDate] = useState(getODate(props.value));
	const [time, setTime] = useState(getOTime(props.value));
	return (
		<label>{props.label}<br/>
		<input type="time" onChange={(e) => {
			if (!e.target || !e.target.value || !e.target.value.match(/\d\d:\d\d/g)) {
				//console.log(e.target.value);
				return;
			}
			setTime(e.target.value);
			let dt = getDate(date, e.target.value, props.tz);
			props.chState(dt);
		}} value={time} required={props.required} />
		<br />
		<input type="date" onChange={(e) => {
			if (!e.target || !e.target.value || !e.target.value.match(/\d\d\d\d-\d\d-\d\d/g)) {
				//console.log(e.target.value);
				return;
			}
			setDate(e.target.value);
			let dt = getDate(e.target.value, time, props.tz);
			props.chState(dt);
		}} value={date} required={props.required} />
		</label>
	);
}
export function DatePickerE(props) {
	return (
		<label>{props.label}<br/>
		<input type="date" name={props.name} onChange={(e) => {props.chState(e.target.value)}} value={props.value} required={props.required} />
		</label>
	);
}

export function TimeRangePicker(props) {
	const scale = 60*1000;
	const [diff, setDiff] = useState((props.end-props.start)/scale);

	//Divider
	return (<>
		<DateTimePickerE label="Start time" name="start" chState={(v) => {
			var v1 = getDate(getODate(v), getOTime(v), props.tz);
			props.chStart(v1.getTime());
			props.chEnd(v1.getTime() + diff*scale);
		}} value={new Date(props.start)} tz={props.tz} required />
		<br />
		<span>{TimeToStr(props.start)}</span>
		<br />
		<hr />
		<RangeE label="End time" name="end" step={1} min={0} max={24*60} chState={(v) => {
			setDiff(v);
			props.chEnd(props.start + v*scale);
		}} value={diff} required />
		<br />
		<span>{TimeToStr(props.end)}</span>
	</>);
}

export function RangeWnE(props) {
	const label = props.label || props.name;
	const [val, setVal] = useState(props.value);

	function handleChangeR1(e) {
		let v = e.target.value;
		setVal(v);
		if (props.float)
			props.chState(parseFloat(v));
		else
			props.chState(parseInt(v));
	}

	return (
		<label>{label}: <span className="rangeVal">{val}</span>
		<input type="range" step={props.step} min={props.min} max={props.max} name={props.name} onChange={handleChangeR1} value={val} required={props.required} />
		</label>
	);
}

export function RangeE(props) {
	return (
		<label>{props.label}<br/>
		<input type="range" step={props.step} min={props.min} max={props.max} name={props.name} onChange={(e) => {props.onChange(e.target.value)}} value={props.value} required={props.required} />
		</label>
	);
}

export function InputBigE(props) {
	const inType = props.type || "text";
	const inClassName = props.inClassName || "inBig";
	return (
		<input className={inClassName} type={inType} placeholder={props.placeholder}
		name={props.name} value={props.value} required={props.required} form={props.form}
		onChange={(e) => {props.onChange(e.target.value)}} />
	);
}

export function InputNumE(props) {
	const br = props.nobr?<></>:<br/>;
	return (
		<label>{props.label}{br}
		<input type="number" step={props.step} min={props.min} max={props.max} name={props.name} value={props.value} style={props.inStyle} onChange={(e) => {props.onChange(e.target.value)}} required={props.required} />
		</label>
	);
}

export function InputNum2E(props) {
	const className = props.className || "flex flex-row font-extrabold text-xl";
	const inClassName = props.inClassName || "text-center w-[90px] p-2";
	const butClassName = props.butClassName || "border border-white w-[40px] p-2";
	const onChange = props.onChange || nop;
	const value = props.value || 1;
  const [num, setNum] = useState(value);

	useEffect(() => {
		onChange(num);
	}, [num, onChange]);
	useEffect(() => {
		setNum(value);
		//console.log("eff Num2E");
	}, [value]);

	function onNumCh(e) {
		let v = parseInt(e.target.value);
		//console.log(v);
		//onChange(v);
		setNum(v);
	}
	function onClickP(e) {
		//onChange(value+1);
		setNum(num+1);
	}
	function onClickM(e) {
		//onChange(value-1);
		setNum(num-1);
	}

	return (
		<div className={className} >
			<ButtonE className={butClassName} onClick={onClickM} >-</ButtonE>
			<input type="number" className={inClassName} value={num} onChange={onNumCh} />
			<ButtonE className={butClassName} onClick={onClickP} >+</ButtonE>
		</div>
	);
}

export function InputCE(props) {
	const type = props.type || "text";
	const name = props.name || "i1";
	const required = props.required || false;
	const value = props.value || "";
	const onChange = props.onChange || nop;
	//(e) => {props.onChange(e.target.value)}
	function onCh(e) {
		onChange(e.target.value);
		/*
		e.target.setCustomValidity("");
		if (props.notInt)
			props.onChange(e.target.value);
		else
			props.onChange(parseInt(e.target.value));
			*/
	}
	return (
		<input className={props.className} type={type} name={name} value={value} onChange={onCh}
		required={required} placeholder={props.placeholder} />
	);
}

export function InputUE(props) {
	const type = props.type || "text";
	const name = props.name || "i1";
	const required = props.required || false;
	const onChange = props.onChange || nop;
	if (props.autofocus)
		return (
			<input className={props.className} type={type} name={name} autoFocus
			required={required} placeholder={props.placeholder} onChange={onChange} />
		);
	else 
		return (
			<input className={props.className} type={type} name={name}
			required={required} placeholder={props.placeholder} onChange={onChange} />
		);
}

export function TextareaUE(props) {
	const name = props.name || "i1";
	const required = props.required || false;
	return (
		<textarea className={props.className} name={name} required={required} ></textarea>
	);
}

export function TextareaCE(props) {
	const required = props.required || false;
	const onChange = props.onChange || nop;
	function onCh(e) {
		onChange(e.target.value);
	}

	return (
		<textarea className={props.className} required={required} 
			value={props.value} onChange={onCh} />
	);
}

export function ButtonE(props) {
	const className = props.className || "font-extrabold border border-white p-3";
	const onClick = props.onClick || nop;
	const type = props.type || "button"; //reset, submit
	const value = props.value || props.children;
	const arg = props.arg || "";
	return (
		<button className={className} type={type} onClick={onClick} data-arg={arg} >
			{value}
		</button>
	);
}

export function InputE(props) {
	return (
		<label>{props.label}<br/>
		<input type="text" name={props.name} value={props.value} onChange={(e) => {props.chState(e.target.value)}} required={props.required} form={props.form} />
		</label>
	);
}

export function InputMailE(props) {
	return (
		<label>{props.label}<br/>
		<input type="email" name={props.name} value={props.value} onChange={(e) => {props.chState(e.target.value)}} required={props.required} form={props.form} />
		</label>
	);
}

export function InputPhotoE(props) {
	const imgEl = useRef(null);
	//const divEl = useRef(null);
	const txt = props.txt || "Take Photo";

	function onCh(e) {
		//props.chState(e.target.value);
		let res = e.target;
		if (!res)
			return;
		res = res.files;
		if (!res || !res.length) {
			imgEl.current.src = "";
			return;
		}
		res = res[0];
		let reader = new FileReader();
		reader.addEventListener("load", (e) => {
			imgEl.current.src = e.target.result;
			//divEl.current.className = "imgp";
			imgEl.current.className = "imgp";
			//props.chState(props.idx, e.target.result);
		});
		reader.readAsDataURL(res);
	}

	//<Icon icon="camera" iconSize={30} className="mr1" />
	//form={props.form}
	return (<>
		<label className="inLab">
			{txt}
			<input type="file" accept="image/*" capture className="inFile" onChange={onCh}
				form={props.form} name={props.name} required={props.required} />
		</label>
		<br />
		<img ref={imgEl} alt="preview" className="dn" />
	</>);
}

export function InputFileE(props) {
	let txt = props.txt || "Upload file";

	function onCh(e) {
		//props.chState(e.target.value);
	}

			//<Icon icon="cloud-upload" iconSize={30} className="mr1" />
	return (
		<label className="inLab">
			{txt}
			<input type="file" className="inFile" onChange={onCh}
				form={props.form} name={props.name} required={props.required} />
		</label>
	);
}

export function InputImgE(props) {
	return (
		<label>{props.label}<br/>
		<input type="file" accept="image/*" capture name={props.name} onChange={(e) => {props.chState(e.target.value)}} required={props.required} form={props.form} />
		</label>
	);
}

export function BoxE1(props) {
	return (
		<fieldset className={props.className}>
			<legend>{props.tit}</legend>
			{props.children}
		</fieldset>
	);
}

//TODO change con Selector e 2 mode row e col
export function SelE(props) {
	const listOpts = props.opts.map((d, idx) => {
		return <option key={idx} value={d.v}>{d.k}</option>;
	});
	function onCh(e) {
		e.target.setCustomValidity("");
		if (props.notInt)
			props.chState(e.target.value);
		else
			props.chState(parseInt(e.target.value));
	}

	return (
		<label>{props.label}<br/>
		<select className="w1" name={props.name} defaultValue={props.hasOwnProperty("value")?props.value:-1} 
		onChange={onCh} required={props.required} >
		<option key="-1" value="-1" disabled>[select]</option>
		{listOpts}
		</select>
		</label>
	);
}

export function SelectorE(props) {
	const className = props.className || "text-black";
	const onChange = props.onChange || nop;
	const options = props.options || [ {v: -1, k: "[select]"} ];
	const defaultValue = props.hasOwnProperty("value")?props.value:-1;
	const listOpts = options.map((d, idx) => {
		/*
		if (d.v === defaultValue)
			return <option key={idx} value={d.v} selected>{d.k}</option>;
		else
		*/
		return <option key={idx} value={d.v}>{d.k}</option>;
	});

	return (
		<select className={className} name={props.name} defaultValue={defaultValue}
			onChange={(e) => onChange(e.target.value)} required={props.required} >
			{listOpts}
		</select>
	);
}

function defaultDisplay(o) {
	return o.attributes.name;
}
function defaultValue(o) {
	if (o && o.id)
		return o.id;
	else
		return 0;
}

export function SelStrE(props) { //semplice con load ma non create
	const nullVal = {v: 0, k: "[select]"};
	const [options, setOptions] = useState([]);
	const className = props.className || "text-black grow";
	const onChange = props.onChange || nop;
	const label = props.label || "";
	const display = props.display || defaultDisplay;
	const valueF = props.valueF || defaultValue;
	const force = props.force || false;
	const nullable = props.hasOwnProperty("nullable")?props.nullable:true;
  let auth = useAuth();
	
	useEffect(() => {
		let isMounted = true;
		let ep = auth.user.endpoint;
		ep += `/api/${props.entity}`;
		if (props.params) {
			ep += `${props.params}`;
		}

		getE(ep, { token: auth.user.token, entity: props.entity, force: force }).then((e) => {
			if (!e || !e.data) {
				console.log(e);
				return;
			}
			//console.dir(e.data);
			let opts = e.data.map((o, idx) => {
				return {v: valueF(o), k: display(o)};
			});
			if (nullable)
				opts = [ nullVal ].concat(opts);
			//console.log(opts);
			if (isMounted)
				setOptions(opts);
			//setPageNum(e.data.attributes.num);
			//console.log(inp);
		});
		return () => { isMounted = false };
	}, []);

	return (
		<label className="flex flex-row gap-x-3">
			<div className="frow">{label}</div>
			<SelectorE options={options} onChange={onChange} value={props.value} className={className} />
		</label>
	);
}

//con creazione
export function SelCreStrE(props) {
	//const [options, setOptions] = useState([ {value: -1, label: "[select]"} ]);
	const [options, setOptions] = useState([ ]);
	const [viewOptions, setViewOptions] = useState([ ]);
	const className = props.className || "text-white";
	const onChange = props.onChange || nop;
	const label = props.label || "";
	//const initialValue = props.initialValue || 0;
	/*
  const allOptions = [
    { label: 'London', value: 'london' },
    { label: 'Sydney', value: 'sydney' },
    { label: 'Shanghai', value: 'shanghai' },
  ]
	*/
  let auth = useAuth();

  const searchHandler = (currentValue) => {
    const createOptions = [{
      value: currentValue, label: 'Add "' + currentValue + '"'
    }]
    if (!currentValue) {
			return setViewOptions([])
		}
    const relatedOptions = options.filter(item => item.label.includes(currentValue))
    const optionsWithCreatable = relatedOptions.length !== 0 ? relatedOptions : createOptions
    setViewOptions(optionsWithCreatable)
  }
  const selectHandler = (currentValue) => {
		console.log(currentValue);
		if (currentValue.startsWith('Add "')) {
		}
  }
	
	useEffect(() => {
		let isMounted = true;
		let ep = auth.user.endpoint;
		ep += `/api/${props.entity}`;
		if (props.params) {
			ep += `${props.params}`;
		}
		getE(ep, { token: auth.user.token, entity: props.entity }).then((e) => {
			if (!e || !e.data) {
				console.log(e);
				return;
			}
			let opts = e.data.map((o, idx) => {
				return {v: o.id, k: o.attributes.name};
			});
			//console.log(opts);
			if (isMounted)
				setOptions(opts);
			//setPageNum(e.data.attributes.num);
			//console.log(inp);
		});
		return () => { isMounted = false };
	}, []);

			//<SelectorE2 options={options} onChange={onChange} className={className} />
	return (
		<label className="flex flex-row gap-x-3">
			{label}
			<InputUE onChange={searchHandler} />
		</label>
	);
}

export function ObjListGenE(arr, tit, toViz) {
	return (props) => {
		const list = arr.map((o, idx) => {
			return  <option key={idx} value={o["id"]}>
				{toViz(o)}
			</option>
		});
		function onCh(e) {
			e.target.setCustomValidity("");
			props.chState(parseInt(e.target.value));
		}

		return (
			<label>{props.label || tit}<br/>
				<select className="w1" name={props.name} form={props.form} onChange={onCh}
					required={props.required} defaultValue={props.value?props.value:-1} >
					<option key={-1} value={-1} disabled>[select]</option>
					{ list }
				</select>
			</label>
		);
	};
}

export function ObjListGen(arr, tit, toViz) {

	return (props) => {
		const def = props.default || "[select]";
		const chState = props.chState || nop;
		const value = props.value || -1;

		const list = arr.map((o, idx) => {
			return  <option key={idx} value={o["id"]}>
				{toViz(o)}
			</option>
		});
		function onCh(e) {
			e.target.setCustomValidity("");
			chState(parseInt(e.target.value));
		}

		return (
			<select className={props.className} name={props.name} form={props.form} onChange={onCh}
				required={props.required} defaultValue={value} >
				<option key={-1} value={-1} disabled>{def}</option>
				{ list }
			</select>
		);
	};
}

export function NumSelectorE1(props) {
	return (
		<div className="flex flex-row gap-x-3">
			<InputNum2E value={props.value} onChange={props.onChange} />
			<div>{props.text}</div>
		</div>
	);
}

/* ------------------------------------------------------- */

/*
export function goBack() {
	history.goBack();
}

export function goHome() {
	history.replace("/");
}
*/

export function setApiEntryPoint(str) {
	api_entry_point = str;
}

export function setReqLocal(fun) {
	reqLocal = fun;
}

export async function useGet(opts) {
}

export class ErrorBoundaryE extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		//logErrorToMyService(error, errorInfo);
		console.dir(error);
		//console.dir(errorInfo);
	}

	render() {
		if (this.state.hasError) {
			if (this.props.dev)
				return <pre>{JSON.stringify(this.state.error)}</pre>;
			else
				return <div>Something went wrong.</div>;
		}
		return this.props.children;
	}
}

export async function postE(endpoint, config) {
	try {
		const opts = {
			method: 'POST',
			headers: { },
		};
		if (config && config.hasOwnProperty("token")) {
			opts.headers["Authorization"] = `bearer ${config.token}`;
		}
		if (config && config.hasOwnProperty("body")) {
			opts.headers["Content-Type"] = 'application/json';
			opts.body = JSON.stringify(config.body);
		}
		if (config && config.hasOwnProperty("formData")) {
			opts.body = config.formData;
		}
		const response = await fetch(endpoint, opts);
		return await response.json();
	}
	catch (e) {
		console.log(e);
	}
}

export async function getE(endpoint, config) {
	try {
		if (config && config.entity && (!config.hasOwnProperty("force") || !config.force) ) {
			let entity = config.entity;
			let value = window.localStorage.getItem(entity);
			//console.log(`${entity}: ${value}`);
			if (value) {
				let response = JSON.parse(value);
				return response;
			}
		}

		const opts = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		};
		if (config && config.hasOwnProperty("token")) {
			opts.headers["Authorization"] = `bearer ${config.token}`;
		}
		let response = await fetch(endpoint, opts);
		response = await response.json();
		if (config && config.entity) {
			let entity = config.entity;
			window.localStorage.setItem(entity, JSON.stringify(response));
		}
		//console.dir(response);
		return response;
	}
	catch (e) {
		console.log(e);
	}
}

export async function fetchGraphQL(text, variables, config) {
	try {
		const endpoint = config.endpoint;
		const opts = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: text,
				variables,
			}),
		};
		if (config.hasOwnProperty("token")) {
			opts.headers["Authorization"] = `bearer ${config.token}`;
		}
		return fetch(endpoint, opts).then((res) => {
			return res.json();
		});
	}
	catch (e) {
		console.log(e);
	}
}

export async function fetchRelay(config) {
	return (params, variables) => {
		//console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`);
		return fetchGraphQL(params.text, variables, config);
	};
}

export async function loadData2(endpoint, setData, reload) {
	try {
		//var ls = "s"+code;
		var ls = "s-"+endpoint;
		var result;
		if (!reload) {
			result = window.localStorage.getItem(ls);
			if (result) {
				setData(JSON.parse(result));
				return true;
			}
		}
		let ep = endpoint || "loadnews";
		result = await doReq("GET", ep);
		//console.log(result);
		//if (result.charAt(0) !== "{") { return false; }
		//result = JSON.parse(result);
		//console.dir(result);
		/*
		if (code === 1 || code === 3) {
		}
		*/
		result.forEach((v) => {
			//v.txt = v.firstname + " " + v.lastname + " " + v.nickname + " " + v.email;
		});
		window.localStorage.setItem(ls, JSON.stringify(result));
		setData(result);
		return true;
	}
	catch(e) {
		console.dir(e);
		console.log("err ld");
		console.log(result);
		return false;
	}
}

export const loadData = async (stateid, setData, reload) => {
	try {
		let res;
		if (!reload) {
			res = window.localStorage.getItem(stateid);
			if (res) {
				setData(JSON.parse(res));
				return true;
			}
		}
		res = await fetch(`${api_entry_point}/state/${stateid}`);
		res = await res.json();
		if (!reload) {
			window.localStorage.setItem(stateid, JSON.stringify(res));
		}
		else {
			window.localStorage.removeItem(stateid);
		}
		setData(res);
		return true;
	}
	catch(e) {
		console.dir(e);
		return false;
	}
};

export function doReq2(type, url, data) {
	return new Promise((resolve, reject) => {
		if (reqLocal) {
			resolve(reqLocal(type, url, data));
			return;
		}
		const xhr = new XMLHttpRequest(); 
		xhr.open(type, url);
		xhr.addEventListener("load", () => { resolve(xhr.responseText); });
		xhr.addEventListener("error", () => { reject(xhr.statusText); });
		xhr.send(data);
	});
}

export async function doReq(type, endpoint, data) {
	try {
		let opts = {};
		//let opts = { credentials: 'include' }; //TODO
		if (type === "POST") {
			//opts["headers"] = { "Content-Type": "multipart/form-data" };
			opts["headers"] = { 
				'Accept': 'application/json',
				"Content-Type": "application/json"
				//'Authorization': "Bearer " + token,
			};
			opts["method"] = "POST";
			opts["body"] = JSON.stringify(data);
		}
		let res = await fetch(api_entry_point+endpoint, opts);
		//res = await res.text();
		res = await res.json();
		return res;
	}
	catch (e) {
		console.dir(e);
		return null;
	}
}

export async function doOp(data, endpoint, afterSucc) {
	//setAleWait();
	let ep = endpoint ? endpoint : set_data;
	let res = await doReq('POST', ep, data);
	if (res && res.msg && res.msg === "ok") {
		//console.log("ok");
		//setAleSucc();
		//incrDataIdxG();
		if (afterSucc) { afterSucc(); }
	}
	else {
		console.dir(res);
		//setAleErr(res && res.msg ? res.msg : "internal server error");
	}
}

export function FormE(props) {
	const subIcon = props.subIcon || "export";
	const subClassName = props.subClassName || "w-48";
	const className = props.className || "flex flex-col justify-center items-center";

	function handleSubmit(event) {
		event.preventDefault();
		let form = event.target;
		if (props.validator) {
			props.validator(form);
			if (!form.reportValidity()) {
				return;
			}
		}

		let data = props.fdata;
		if (props.opCode)
			data.f = props.opCode;
		//console.dir(obj);
		//let data = new FormData();
		//data.append("f", props.opCode);
		//if (props.cookie)
		//data.append("sid", cookie);
		//data.append("data", JSON.stringify(obj));
		//if (props.file) {
			//console.log("file "+props.file+" "+form.elements[props.file]);
			//data.append("file", form.elements[props.file].files[0]);
		//}
		//if (props.req)
		//props.req(data);
		//promise
		//let data = JSON.stringify(obj);
		let ep = false;
		if (props.endpoint)
			ep = props.endpoint;
		doOp(data, ep, props.afterSucc);
		return true;
	}

	if (props.formId) {
		return (
			<>
			{props.children}
			<form id={props.formId} onSubmit={handleSubmit} className={className} >
			<button icon={subIcon} className={subClassName} type="submit" disabled={props.disabled} >
			{props.subTxt}
			</button>
			</form>
			</>
		);
	}
	else {
		return (
			<form onSubmit={handleSubmit} className={className} >
			{props.children}
			<button icon={subIcon} className={subClassName} type="submit" disabled={props.disabled} >
			{props.subTxt}
			</button>
			</form>
		);
	}
}

function setCVnRm(e) {
	e.target.setCustomValidity("");
	e.target.removeEventListener("change", setCVnRm);
}

export function setValidityFail(el, msg) {
	el.setCustomValidity(msg);
	el.addEventListener("change", setCVnRm);
}

export function PaginationE(props) {
	const className = props.className || "flex flex-row";
	const butCN = props.butCN || "bg-gray-700 p-4 border grow text-center";
	const onChange = props.onChange || nop;
	let page = props.page || 1;
	let count = props.count || 1;
	let pageC;
	if (props.page) {
		if (!props.count)
			pageC = <div className={butCN}>{page}</div>;
		else
			pageC = <div className={butCN}>{page}/{count}</div>;
	}
	else {
		pageC = <></>
	}

  return (
		<div className={className} >
			<ButtonE onClick={() => onChange(page-1)} className={butCN}>prev</ButtonE>
			{pageC}
			<ButtonE onClick={() => onChange(page+1)} className={butCN}>next</ButtonE>
		</div>
	);
}

function hex(arrayBuffer) {
	return Array.prototype.map.call(
		new Uint8Array(arrayBuffer),
		n => n.toString(16).padStart(2, "0")
	).join("");
}

async function sha512(str) {
	let res = await crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str));
	//res = new TextDecoder().decode(res);
	res = hex(res);
	return res;
}

export function Locker1(props) {
	const k = props.k;

  async function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
		let inp = formData.get("inp");
		inp = await sha512(inp);
		inp = await sha512(inp);
		if (inp == k) {
			props.callbackLoginOk();
		}
  }

	//<div className="flex flex-col gap-y-8 items-center pt-[190px] justify-center">
	return	(
		<form onSubmit={handleSubmit} className="flex flex-col gap-y-6 items-center justify-center h-[500px] text-5xl font-extrabold">
			<InputUE className="w-[250px] border-2 border-black rounded p-3" 
			name="inp" autofocus={true} type="password" required />
			<ButtonE type="submit" className="w-[250px] border-2 border-black rounded p-3">
				Unlock
			</ButtonE>
		</form>
	);
}

export function AppLocker(props) {
  //let location = useLocation();
  //let from = location.state?.from?.pathname || "/";
  //let navigate = useNavigate();
	const [locked, setLocked] = useState(true);
	const k = props.k || "ba7902a4dd139795071525351638b678e543ff4917043bdfd6efdfa7cbce242eb2443045274db7e96d1297f28dd84602eeaace432a02b70ec1f805a792d4ebb6";

	const Locker = props.locker || Locker1;
	//const LockerR = RendererE(Locker);

  function callbackLoginOk(event) {
		setLocked(false);
		//navigate(from, { replace: true });
	}
	
  if (locked)
		return <Locker k={k} callbackLoginOk={callbackLoginOk} />;
	else
		return props.children;
}

export function StrapiLoader(endpoint, entity, opts) {
	return async ({ request, params }) => {
		//console.dir(request);
		let auth = opts.auth;
		let populate = opts.populate;
		if (!endpoint && !auth)
			return null;
		let ep = endpoint || auth.user.endpoint;
		//let ep = obj.ep;
		//?populate=*`
		if (!populate)
			populate = "";
		if (params.ids) {
			ep += `/api/${entity}/${params.ids}?${populate}`;
		}
		else {
			ep += `/api/${entity}/?${populate}`;
		}
		if (auth) {
			return getE(ep, {token: auth.user.token}).then((e) => {
				if (!e || !e.data) {
					console.dir(e);
					return null;
				}
				//if (!params.ids && !e.data.length)
					//return null;
				//console.log("loader");
				//console.dir(e.data);
				return e.data;
			});
		}
		else {
			return getE(ep).then((e) => {
				if (!e || !e.data) {
					console.dir(e);
					return null;
				}
				return e.data;
			});
		}
		/*
		if (res.status === 404) {
			throw new Response("Not Found", { status: 404 });
		}
		*/
	}
}

export function RequireAuth(props) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children;
}

const strapiAuth = {
  signin(opts, callback) {
		//if (!authReq.isAuthenticated) {
		//let psw = opts.get("password");
		let ep = opts.endpoint;
		ep += "/api/auth/local";
		postE(ep, {formData: opts.formData}).then((e) => {
			let user = e.user;
			user.token = e.jwt;
			user.endpoint = opts.endpoint;
			if (callback)
				callback(user);
		}).catch((e) => {
			console.dir(e);
			console.log("login failed");
		});
  },
  signout(opts, callback) {
		if (callback)
			setTimeout(callback, 50);
  }
};

let AuthContext = React.createContext();
export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider(props) {
		//window.localStorage.setItem("jwt", e.jwt);
		//window.localStorage.setItem("user", e.user);
	let endpoint = props.endpoint || "";
	let authReq = props.authReq || strapiAuth;
	let sUser = window.localStorage.getItem("user");
	if (sUser) {
		try {
			sUser = JSON.parse(sUser);
		}
		catch (e) {
			sUser = null;
		}
	}
	else {
		sUser = null;
	}
  const [user, setUser] = useState(sUser);

  let signin = (opts, callback) => {
		opts.userOld = user;
		opts.endpoint = endpoint;
    return authReq.signin(opts, (resUser) => {
			window.localStorage.setItem("user", JSON.stringify(resUser));
      setUser(resUser);
			if (callback)
				callback(resUser);
    });
  };

  let signout = (callback) => {
    return authReq.signout(user, () => {
			window.localStorage.setItem("user", null);
      setUser(null);
			if (callback)
				callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export function BaseInfoE(props) {
	const name = props.name || "name";
	const args = props.args || null;
	const onData = props.onData || nop;
	const className = props.className || null;
  //const data = props.data || "";
	//const setData = props.setData || nop;
	//const inp = props.inp || {"op": name};
	//const endpoint = props.endpoint || "";

	function doOp() {
    let formData = new FormData();
		formData.append('name', name);
		if (args) {
			formData.append('args', JSON.stringify(args));
		}

		let auth = props.auth;
		let ep = auth.user.endpoint;
		if (props.endpoint)
			ep += props.endpoint;
		postE(ep, {token: auth.user.token, formData: formData}).then(onData);
	}

	return (
		<ButtonE onClick={doOp} className={className} >{name}</ButtonE>
	);
}

export function SetLS(props) {
	const key = props.key1 || "set";
	function go() {
		if (!props.key1)
			return;
		let value = props.value || "";
		window.localStorage.setItem(props.key1, value);
	}

	return (
		<ButtonE onClick={go} >{key}</ButtonE>
	);
}

export function GetLS(props) {
	const key = props.key1 || "get";
  const [value, setValue] = useState("");

	function go() {
		if (!props.key1)
			return;
		let v = window.localStorage.getItem(props.key1);
		if (props.alert)
			alert(value);
		//console.dir(value);
		setValue(v);
	}

	return (
		<div>
			<ButtonE onClick={go} >{key}</ButtonE>
			<pre>{value}</pre>
		</div>
	);
}

export function revalidateFromUrl(url) {
	return ({ currentUrl }) => {
		return currentUrl.pathname === url;
	}
}

export function alwaysTrue() {
	return true;
}

export function alwaysFalse() {
	return true;
}

